<template>
  <div class="d-flex pa-5 text-caption align-center">
    <router-link
      v-for="(breadcrumb, idx) of breadcrumbs"
      :key="idx"
      class="d-flex align-center text-decoration-none"
      :class="
        breadcrumb.disabled
          ? 'primary--text cursor-default'
          : 'ap-light-blue--text'
      "
      :to="breadcrumb.link ? breadcrumb.link : ''"
    >
      <v-icon v-if="idx !== 0" color="primary" size="20" class="mx-1">
        $mdi-chevron-right
      </v-icon>
      {{ breadcrumb.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumb
    },
  },
}
</script>
